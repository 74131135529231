import axios, { AxiosResponse } from "axios";
import { IUser } from "../types/IUser";
import { apiUrl } from "../EposConfig";

const API = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: () => true,
});

export const authenticate = (
  email: string,
  password: string
): Promise<AxiosResponse | void> => {
  const data = {
    username: email,
    password: password,
  };
  return API.post("Login/Login", data)
    .then((response: AxiosResponse<IUser>) => {
      return response;
    })
    .catch(handleError);
};

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}
